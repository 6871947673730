import request from "@/utils/request";

// 根据商机id获取产品信息
export function fetchBusinessInfoById(params) {
  return request({
    url: "/crm/currency/getCostProduct",
    method: "get",
    params
  });
}
// 新增商机
export function fetchAddBusiness(data) {
  return request({
    url: "/crm/cost/addCost",
    method: "post",
    data
  });
}

// 获取商机列表
export function fetchBussinessData(params) {
  return request({
    url: "/crm/cost/getList",
    method: "get",
    params
  });
}

// 新增关联联系人
export function addCostContacts(data) {
  return request({
    url: "/crm/cost/addCostContacts",
    method: "post",
    data
  });
}

// 查询列表
export function selectPage(query) {
  return request({
    url: "/crm/cost/getList",
    method: "get",
    params: query
  });
}

// 删除客户
export function delList(ids) {
  return request({
    url: "/crm/cost/deleteCost/" + ids,
    method: "delete"
  });
}

// 转为客户
export function changeClient(data) {
  return request({
    url: "/crm/clue/transferToCustomer/" + data,
    method: "PUT"
  });
}

// 转移商机
export function fetchTransferCus(leaderId, costIds) {
  return request({
    url: `/crm/cost/transferCost/${leaderId}/${costIds}`,
    method: "put"
  });
}

// 获取数量
export function fetchCount(query) {
  return request({
    url: "/crm/cost/getNumber",
    method: "get"
  });
}

// 添加团队成员
export function submitMembers(id, userIds) {
  return request({
    url: `/crm/cost/addRelatedTeam/${id}/${userIds}`,
    method: "post"
  });
}

// 根据id获取单条记录
export function getDetailByCostId(params) {
  return request({
    url: "/crm/cost/getId",
    method: "get",
    params
  });
}

// 查询跟进列表
export function fetchCostFollowList(query) {
  return request({
    url: "/crm/cost/getFollowList",
    method: "get",
    params: query
  });
}

// 删除跟进
export function delFollowList(data) {
  return request({
    url: "/crm/cost/deleteFollow",
    method: "delete",
    data
  });
}

//
export function addFollowCost(data) {
  return request({
    url: "/crm/cost/addFollow",
    method: "POST",
    data
  });
}

// 编辑跟进
export function editFollowCost(data) {
  return request({
    url: "/crm/cost/updateFollow",
    method: "PUT",
    data
  });
}
// 获取联系人列表
export function fetchContactList(query) {
  return request({
    url: "/crm/cost/getListCostContacts",
    method: "get",
    params: query
  });
}

// 设为首要联系人
export function setMainContact(data) {
  return request({
    url: "/crm/cost/updateCostContacts",
    method: "PUT",
    data
  });
}

//解除关联联系人
export function deleteCostContacts(query) {
  return request({
    url: "/crm/cost/deleteCostContacts",
    method: "delete",
    params: query
  });
}
// 客户管理，团队成员,
export function fetchTeamList(query) {
  return request({
    url: "/crm/cost/listRelatedTeam",
    method: "get",
    params: query
  });
}
// 移除团队成员
export function removeUser(params) {
  return request({
    url: "/crm/cost/deleteRelatedTeam",
    method: "delete",
    params
  });
}

// 获取产品
export function fetchProductList(params) {
  return request({
    url: "/crm/cost/getProductList",
    method: "get",
    params
  });
}

// 编辑商机
export function updateBusiness(data) {
  return request({
    url: "/crm/cost/updateCost",
    method: "put",
    data
  });
}

// 新建产品
export function addProduct(data) {
  return request({
    url: "/crm/cost/addCostProduct",
    method: "post",
    data
  });
}

export function updateProduct(data) {
  return request({
    url: "/crm/cost/updateCostProduct",
    method: "put",
    data
  });
}

// 删除产品
export function removeProduct(params) {
  return request({
    url: "/crm/cost/deleteCostProduct/" + params,
    method: "delete"
    // params
  });
}


// 新建临时商机
export function addTempCost() {
  return request({
    url: '/crm/cost/addTempCost',
    method: 'get'
  })
}

// 删除临时商机
export function delTempCost() {
  return request({
    url: '/crm/cost/delTempCost',
    method: 'delete'
  })
}
